@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';

* {
    margin: 0;
    padding: 0;
}
*:focus {
    outline: none;
}
* {
    word-wrap: break-word;
}
.clearer {
    width: 100%;
    clear: both;
}

html,
body {
    height: 100%;
    min-width: 1200px;
}
body {
    margin: 0;
    font-family: 'FuturaPT-Book', Helvetica, sans-serif !important;
}

h1,
h2,
h3 {
    font-family: 'FuturaPT-Book', Helvetica, sans-serif !important;
}

[hidden] {
    display: none;
}

.opaque {
    opacity: 0.4;
}

.underlined {
    text-decoration: underline;
}

.-flexAlignContentCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-tab-label {
    // color: #fd4462;
    font-weight: 400;
}

.mat-tab-label.mat-tab-label-active {
    color: #fd4462;
    font-weight: 700;
}

.mat-ink-bar {
    background-color: #fd4462 !important;
}

//SNACKBAR

.mat-mdc-simple-snack-bar {
    color: #edbb77;
}

.mst-snackbar__error {
    color: #fd4462;
    font-weight: bold;
}

.mst-snackbar__success {
    color: #2edf1d;
    font-weight: bold;
}

// PAGES

.mst-userTrainingPlan {
    &__infoDate {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        & .mat-form-field-wrapper {
            padding: 0;
        }
    }
}

.ng-bar-placeholder {
    height: 3px !important;
}

// EDITOR

.mst-editorToolbar {
    border-color: #e4e4e4 !important;
    border-radius: 4px 4px 0 0;
}


.ngx-timepicker {
    text-align: center;
    font-size: medium;
    height: 50px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-top: 20px;
    padding: 7px;

    &-control__arrows {
        top: -5px !important;
    }

    &-control__arrow {
        font-size: 6px !important;
    }
    &__toggle {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        margin-left: 20px;
        padding-left: 5px;
    }

    & .ngx-timepicker-control.ngx-timepicker-control--active::after {
        display: none;
    }
}

.mst-users {
    &__filters {
        align-items: flex-start !important;

        & mat-slide-toggle.mat-mdc-slide-toggle {
            margin-top: 17px !important;
        }
    }
    &__table {
        & thead {
            & tr {
                & th {
                    font-size: 12px;
                    letter-spacing: 0;
                    padding: 0 8px;
                }
            }
        }
        & tbody {
            & tr {
                & td {
                    font-size: 12px;
                    letter-spacing: 0;
                    padding: 0 8px;

                    & mat-chip {
                        height: auto !important;
                        padding: 0 !important;
                        margin: 0;

                        & span {
                            font-size: 12px;
                        }
                        & [matchipaction] {
                            padding: 0 8px !important;

                            & mat-chip-listbox {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs {
    & > .mat-mdc-tab-header {
        & .mat-mdc-tab-labels {
            & .mdc-tab {
                flex-grow: initial;

                &.mdc-tab-indicator--active {
                    & .mdc-tab__text-label {
                        color: #000;
                    }
                    & .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                        border-color: #000;
                    }
                }
            }
        }
    }
}
.mst-dateRange {
    & mat-label {
        font-size: 14px;
        letter-spacing: 0;
    }
    & .mat-date-range-input-wrapper {
        max-width: none;

        & input,
        & span {
            font-size: 14px;
            letter-spacing: 0;
        }
    }
}
.mat-mdc-form-field {
    & mat-label {
        font-size: 14px;
        letter-spacing: 0;
    }
    .mat-mdc-input-element {
        font-size: 14px !important;
        letter-spacing: 0 !important;
    }
}

.mst-userTrainingPlan {
    width: calc(100% - 100px);
    max-width: none;
    padding: 0 50px;
}
.mst-titleWrapper {
    width: calc(100% - 100px) !important;
    padding: 40px 50px 24px !important;
}
.mst-title__actions button[mat-flat-button] {
    border-radius: 4px !important;
}

.mst-excelExportBtn {
    box-shadow: none;
}

/* --------------------------------- AG-GRID -------------------------------- */

.ag-theme-material {
    & .mst-menu-cell {
        display: flex;
        justify-content: center;
    }
    & .mst-cell {
        &__training {
            background-color: rgba(255, 64, 129, 0.4);
        }
        &__nutritional {
            background-color: rgba(130, 106, 237, 0.4);
        }
        &__gym {
            background-color: rgba(32, 6, 59, 0.4);
        }
        &__pregnancyRookie {
            background-color: rgba(61, 0, 125, 0.4);
        }
        &__pregnancyAdvanced {
            background-color: rgba(8, 0, 125, 0.4);
        }
    }
    & .ag-header-row-column-filter {
        background-color: #f5f5f5;
    }
}
